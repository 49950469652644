import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tm-modal-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modalNames, (modalName) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getModalComponent(modalName)), _mergeProps({ key: modalName }, _ctx.getProps(modalName)), null, 16))
    }), 128))
  ]))
}