
import { defineComponent } from 'vue'
import type { SizeProp } from '@/definitions/shared/types'
import TmModalContainer from '@/components/modals/TmModalContainer.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import kebabCase from 'lodash/kebabCase'

export default defineComponent({
  name: 'PageAuth',
  components: { TmScrollbar, TmModalContainer },
  props: {
    logo: {
      type: String,
      default: 'logo-with-text.svg',
    },
    hideLogo: {
      type: Boolean,
    },
    size: {
      type: String as SizeProp<'' | 'medium' | 'large'>,
      default: '',
    },
    showInfo: {
      type: Boolean,
    },
  },
  setup() {
    return {
      kebabCase,
    }
  },
})
