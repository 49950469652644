
import { defineComponent, ref } from 'vue'
import { keyCodes } from '@/definitions/_general/_data/keyCodes'

const { keyLeft, keyRight } = keyCodes

export default defineComponent({
  props: {
    length: {
      type: Number,
      default: 6,
    },
    codeValid: {
      type: Boolean,
      default: true,
    },
    disable: {
      type: Boolean,
    },
    showError: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'done'],
  setup(props, context) {
    const cells = ref([...Array(props.length)])
    const activeCell = ref(0)
    const refs: any = ref([])

    const isCellValid = (i: any) => {
      return !!cells.value[i].match('^\\d{1}$')
    }

    const cellChanged = (i: any) => {
      if (!isCellValid(i)) {
        cells.value[i] = ''
        return
      }

      const value = cells.value.join('')
      // Code validation
      context.emit('update:modelValue', value)

      if (value.length === props.length) {
        context.emit('done')
      }

      focusNextCell(i)
    }

    const focusNextCell = (i: any) => {
      activeCell.value = i + 1

      if (!refs.value[activeCell.value]) return

      refs.value[activeCell.value].$el.querySelector('input').focus()
    }

    const focusPrevCell = (i: any) => {
      activeCell.value = i - 1

      if (!refs.value[activeCell.value]) activeCell.value = 0

      refs.value[activeCell.value].$el.querySelector('input').focus()
    }

    const onKeyDown = (e: KeyboardEvent) => {
      switch (e.code) {
        case keyLeft:
          focusPrevCell(activeCell.value)
          break

        case keyRight:
          focusNextCell(activeCell.value)
          break

        default:
          break
      }
    }

    return {
      cells,
      focusNextCell,
      focusPrevCell,
      activeCell,
      refs,
      onKeyDown,
      isCellValid,
      cellChanged,
    }
  },
})
