import type { ModalProps } from '@/compositions/modals'
import { useModals } from '@/compositions/modals'

const useEditEmailModal = (email: string | string[], options: ModalProps = {}): () => void => {
  const { openModal } = useModals()

  const editEmailModal = () => openModal('singleInput', {
    modalTitle: 'Edit email address ',
    description: [
      'If you’d like to change your email address from ',
      {
        text: email,
        style: 'semi-bold',
      },
      ' to a new one, please enter the new email in the field below. We will resend a verification code to the new address.',
    ],
    btnText: 'Save and resend',
    label: 'New email address',
    value: email,
    placeholder: 'Enter new email address',
    errorHint: 'Please enter user email address to resend the invitation.',
    ...options,
  })

  return editEmailModal
}

export default useEditEmailModal
