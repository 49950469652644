import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-032a2619"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-center align-center" }
const _hoisted_2 = {
  key: 0,
  class: "confirmation-separator hero-24 px-1"
}
const _hoisted_3 = {
  key: 0,
  class: "mt-2 text-center error--text body-text-regular-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["code-confirmation", {
      'tm-error': !_ctx.codeValid
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cells, (cell, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: "d-flex align-center"
        }, [
          _createVNode(_component_tm_field, {
            ref_for: true,
            ref: el => { if (el) _ctx.refs[i] = el },
            modelValue: _ctx.cells[i],
            "onUpdate:modelValue": [($event: any) => ((_ctx.cells[i]) = $event), ($event: any) => (_ctx.cellChanged(i))],
            class: "confirmation-input",
            autofocus: i === 0,
            disable: _ctx.disable,
            onFocus: ($event: any) => (_ctx.activeCell = i),
            onKeyup: _withKeys(($event: any) => (_ctx.focusPrevCell(i)), ["delete"]),
            onKeydown: _ctx.onKeyDown
          }, null, 8, ["modelValue", "onUpdate:modelValue", "autofocus", "disable", "onFocus", "onKeyup", "onKeydown"]),
          (i === 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, "-"))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    (!_ctx.codeValid && _ctx.showError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, " The code is not valid. Please try again. "))
      : _createCommentVNode("", true)
  ], 2))
}