
import { defineComponent, ref } from 'vue'
import PageAuth from '@/components/layout/PageAuth.vue'
import TmCountdown from '@/components/shared/TmCountdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import CodeConfirmation from '@/components/pages/auth/CodeConfirmation.vue'
import useEditEmailModal from '@/compositions/editEmailModal'

export default defineComponent({
  components: {
    PageAuth,
    TmCountdown,
    TmButton,
    CodeConfirmation,
  },
  setup() {
    const code = ref('')

    const email = ref('jamesbrown@gmail.com')

    const editEmailModal = useEditEmailModal(email.value)

    return {
      code,
      email,
      editEmailModal,
    }
  },
})
