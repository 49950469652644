import { defineAsyncComponent } from 'vue'
import type { ModalNames } from '@/components/modals/types'

export type ModalConfigItem = {
  component?: any;
}

export const modalsConfig: Record<ModalNames, ModalConfigItem> = {
  timezone: {
    component: defineAsyncComponent(() => import('@/components/modals/account/TimeZoneModal.vue')),
  },
  saveView: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/SaveView.vue')),
  },
  tickets: {
    component: defineAsyncComponent(() => import('@/components/modals/tickets/TicketsModal.vue')),
  },
  tasksEditDetails: {
    component: defineAsyncComponent(() => import('@/components/modals/tasks/TasksEditDetailsModal.vue')),
  },
  workspaceInviteUser: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/workspace/WorkspaceInviteUserModal.vue')),
  },
  servicesNewSubscribe: {
    component: defineAsyncComponent(() => import('@/components/modals/services/forms/ServicesFormsNewSubscribe.vue')),
  },
  emailPreview: {
    component: defineAsyncComponent(() => import('@/components/modals/chats/EmailPreviewModal.vue')),
  },
  addStatus: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/StatusAddModal.vue')),
  },
  sendTestEmail: {
    component: defineAsyncComponent(() => import('@/components/modals/chats/SendTestEmailModal.vue')),
  },
  ticketsMerge: {
    component: defineAsyncComponent(() => import('@/components/modals/tickets/TicketsMerge.vue')),
  },
  sendTestSms: {
    component: defineAsyncComponent(() => import('@/components/modals/chats/SendTestSmsModal.vue')),
  },
  tasksDetails: {
    component: defineAsyncComponent(() => import('@/components/modals/tasks/TasksDetailsModal.vue')),
  },
  ticketSettingsSender: {
    component: defineAsyncComponent(() => import('@/components/modals/tickets/settings/TicketSettingsSenderModal.vue')),
  },
  newFilteredView: {
    component: defineAsyncComponent(() => import('@/components/modals/settings/NewFilteredViewModal.vue')),
  },
  singleAssignee: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/SingleAssigneeModal.vue')),
  },
  tasksForm: {
    component: defineAsyncComponent(() => import('@/components/modals/tasks/TasksNewBoardModal.vue')),
  },
  tasks: {
    component: defineAsyncComponent(() => import('@/components/modals/tasks/TasksModal.vue')),
  },
  thanks: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/ThanksModal.vue')),
  },
  callsRouting: {
    component: defineAsyncComponent(() => import('@/components/modals/chats/CallsRoutingModal.vue')),
  },
  businessHours: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/BusinessHoursModal.vue')),
  },
  workspaceUsersSubAccount: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/workspace/WorkspaceUsersSubAccountModal.vue')),
  },
  workspaceDeactivateSubAccount: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/workspace/WorkspaceDeactivateSubAccountModal.vue')),
  },
  workspaceSubAccount: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/workspace/WorkspaceSubAccountModal.vue')),
  },
  connectInbox: {
    component: defineAsyncComponent(() => import('@/components/modals/settings/ConnectInboxModal.vue')),
  },
  timeDisplayFormat: {
    component: defineAsyncComponent(() => import('@/components/modals/account/TimeDisplayFormatModal.vue')),
  },
  changePassword: {
    component: defineAsyncComponent(() => import('@/components/modals/account/ChangePasswordModal.vue')),
  },
  backupVerificationCodes: {
    component: defineAsyncComponent(() => import('@/components/modals/account/BackupCodesModal.vue')),
  },
  accountSettingsDetails: {
    component: defineAsyncComponent(() => import('@/components/modals/account/settings/AccountDetailsModal.vue')),
  },
  accountSettingsClose: {
    component: defineAsyncComponent(() => import('@/components/modals/account/settings/AccountCloseModal.vue')),
  },
  workspaceRolesUpdate: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/workspace/WorkspaceRolesUpdateModal.vue')),
  },
  webWidgets: {
    component: defineAsyncComponent(() => import('@/components/modals/chats/settings/web-widgets/WebWidgetsModal.vue')),
  },
  workspaceDelete: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/workspace/WorkspaceDeleteModal.vue')),
  },
  workspaceTeams: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/workspace/WorkspaceTeamsUpdateModal.vue')),
  },
  workspaceSAML: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/workspace/WorkspaceSAMLModal.vue')),
  },
  workspaceWhiteList: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/workspace/WorkspaceWhiteListModal.vue')),
  },
  workspaceUsersInvite: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/workspace/WorkspaceUsersInviteModal.vue')),
  },
  workspaceUsersImport: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/workspace/WorkspaceUsersImportModal.vue')),
  },
  workspaceRolesDelete: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/workspace/WorkspaceDeleteRolesModal.vue')),
  },
  workspaceDeleteTeams: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/workspace/WorkspaceDeleteTeamsModal.vue')),
  },
  workspaceTeam: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/workspace/WorkspaceTeamModal.vue')),
  },
  workspaceUsersEmail: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/workspace/WorkspaceUsersEmailModal.vue')),
  },
  workspaceEditUser: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/workspace/WorkspaceEditUserModal.vue')),
  },
  accountSettingsLogins: {
    component: defineAsyncComponent(() => import('@/components/modals/account/settings/AccountLoginsModal.vue')),
  },
  accountSettingsPhoneVerification: {
    component: defineAsyncComponent(() => import('@/components/modals/account/settings/AccountPhoneVerificationModal.vue')),
  },
  accountSecurityQuestion: {
    component: defineAsyncComponent(() => import('@/components/modals/account/settings/AccountSecurityQuestionModal.vue')),
  },
  accountSettingsChangePassword: {
    component: defineAsyncComponent(() => import('@/components/modals/account/settings/AccountChangePasswordModal.vue')),
  },
  accountIpWhitelist: {
    component: defineAsyncComponent(() => import('@/components/modals/account/settings/AccountIpWhitelistModal.vue')),
  },
  accountSettingsBilling: {
    component: defineAsyncComponent(() => import('@/components/modals/account/settings/AccountBillingModal.vue')),
  },
  accountSettingsSAML: {
    component: defineAsyncComponent(() => import('@/components/modals/account/settings/AccountSAMLModal.vue')),
  },
  confirmation: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/ConfirmationModal.vue')),
  },
  confirmedDeletion: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/ConfirmedDeletion.vue')),
  },
  contact: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/shared/ContactModal.vue')),
  },
  contactAddActivity: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/shared/ContactAddActivityModal.vue')),
  },
  organizationAddActivity: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/organizations/OrganizationAddActivityModal.vue')),
  },
  pinModal: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/shared/PinModal.vue')),
  },
  chatsImageAttachment: {
    component: defineAsyncComponent(() => import('@/components/modals/chats/ChatsImageAttachmentModal.vue')),
  },
  note: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/NoteModal.vue')),
  },
  chatsMessageDetails: {
    component: defineAsyncComponent(() => import('@/components/modals/chats/ChatsMessageDetailsModal.vue')),
  },
  newMessagePreview: {
    component: defineAsyncComponent(() => import('@/components/modals/chats/NewMessagePreviewModal.vue')),
  },
  singleInput: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/SingleInputModal.vue')),
  },
  duplicate: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/DuplicateModal.vue')),
  },
  singleSelect: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/SingleSelectModal.vue')),
  },
  statusSelect: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/StatusSelectModal.vue')),
  },
  singleTags: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/SingleTagsModal.vue')),
  },
  upgradePlan: {
    component: defineAsyncComponent(() => import('@/components/modals/account/billing/UpgradePlanModal.vue')),
  },
  cancelBYOC: {
    component: defineAsyncComponent(() => import('@/components/modals/account/billing/CancelByocModal.vue')),
  },
  autoRecharge: {
    component: defineAsyncComponent(() => import('@/components/modals/account/billing/AutoRechargeModal.vue')),
  },
  newPaymentMethod: {
    component: defineAsyncComponent(() => import('@/components/modals/account/billing/NewPaymentModal.vue')),
  },
  downgradePlan: {
    component: defineAsyncComponent(() => import('@/components/modals/account/billing/DowngradePlanModal.vue')),
  },
  subAccountsNew: {
    component: defineAsyncComponent(() => import('@/components/modals/account/subAccounts/SubAccountsNew.vue')),
  },
  subAccountsClose: {
    component: defineAsyncComponent(() => import('@/components/modals/account/subAccounts/SubAccountsClose.vue')),
  },
  subAccountsEdit: {
    component: defineAsyncComponent(() => import('@/components/modals/account/subAccounts/SubAccountsEdit.vue')),
  },
  contactsGroupDelete: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/shared/ContactsGroupDeleteModal.vue')),
  },
  contactsLists: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/lists/ContactsListsModal.vue')),
  },
  contactsListsMerge: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/lists/ContactsListsMergeModal.vue')),
  },
  contactsListsTable: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/lists/ContactsListsTableModal.vue')),
  },
  contactsTableModal: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/ContactsTableModal.vue')),
  },
  segmentsTableModal: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/segments/SegmentsTableModal.vue')),
  },
  composeSchedule: {
    component: defineAsyncComponent(() => import('@/components/modals/compose/ComposeScheduleModal.vue')),
  },
  contactsSegments: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/segments/ContactsSegmentsModal.vue')),
  },
  contactsOrganizations: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/organizations/ContactsOrganizationsModal.vue')),
  },
  contactsImportDelete: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/import/ContactsImportDeleteModal.vue')),
  },
  addExistingContacts: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/shared/addExistingContacts/AddExistingContacts.vue')),
  },
  contactsBlockedUnblock: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/blocked/ContactsBlockedUnblockModal.vue')),
  },
  contactsBlockedForm: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/blocked/ContactsBlockedFormModal.vue')),
  },
  contactsUnsubscribedSettings: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/unsubscribed/ContactsUnsubscribedSettingsModal.vue')),
  },
  matchTable: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/MatchTableModal.vue')),
  },
  contactsImportNumberValidation: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/import/ContactsImportNumberValidationModal.vue')),
  },
  contactsMove: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/lists/ContactsMoveModal.vue')),
  },
  contactsSettings: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/ContactsSettings.vue')),
  },
  bulkEdit: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/BulkEditModal.vue')),
  },
  composeInsertTemplate: {
    component: defineAsyncComponent(() => import('@/components/modals/compose/ComposeInsertTemplateModal.vue')),
  },
  composePreview: {
    component: defineAsyncComponent(() => import('@/components/modals/compose/ComposePreviewModal.vue')),
  },
  campaignPreview: {
    component: defineAsyncComponent(() => import('@/components/modals/compose/CampaignPreviewModal.vue')),
  },
  composeAudioRecordings: {
    component: defineAsyncComponent(() => import('@/components/modals/compose/ComposeAudioRecordingsModal.vue')),
  },
  historySmsFilter: {
    component: defineAsyncComponent(() => import('@/components/modals/history/sms/HistorySmsFilterModal.vue')),
  },
  historySmsInfo: {
    component: defineAsyncComponent(() => import('@/components/modals/history/sms/HistorySmsInfoModal.vue')),
  },
  attachFile: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/AttachFileModal.vue')),
  },
  uploadedFile: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/UploadedFileModal.vue')),
  },
  allowedEmailsNew: {
    component: defineAsyncComponent(() => import('@/components/modals/settings/allowedEmails/AllowedEmailsAddNew.vue')),
  },
  allowedEmailsSettings: {
    component: defineAsyncComponent(() => import('@/components/modals/settings/allowedEmails/AllowedEmailsSettings.vue')),
  },
  servicesApplySender: {
    component: defineAsyncComponent(() => import('@/components/modals/services/countries/ApplySenderModal.vue')),
  },
  servicesByocThanks: {
    component: defineAsyncComponent(() => import('@/components/modals/services/byoc/ServicesByocThanksModal.vue')),
  },
  servicesByocRequestProvider: {
    component: defineAsyncComponent(() => import('@/components/modals/services/byoc/ServicesByocRequestProviderModal.vue')),
  },
  servicesByocLinkNumbers: {
    component: defineAsyncComponent(() => import('@/components/modals/services/byoc/ServicesByocLinkNumbersModal.vue')),
  },
  servicesByocTwilioLinkNumbers: {
    component: defineAsyncComponent(() => import('@/components/modals/services/byoc/ServicesByocTwilioLinkNumbersModal.vue')),
  },
  servicesByocPlan: {
    component: defineAsyncComponent(() => import('@/components/modals/services/byoc/ServicesByocPlanModal.vue')),
  },
  servicesByocLabel: {
    component: defineAsyncComponent(() => import('@/components/modals/services/byoc/ServicesByocLabelModal.vue')),
  },
  servicesByocLogin: {
    component: defineAsyncComponent(() => import('@/components/modals/services/byoc/ServicesByocLoginModal.vue')),
  },
  servicesByocLoginKeyId: {
    component: defineAsyncComponent(() => import('@/components/modals/services/byoc/ServicesByocLoginKeyIdModal.vue')),
  },
  servicesByocLoginPassword: {
    component: defineAsyncComponent(() => import('@/components/modals/services/byoc/ServicesByocLoginPasswordModal.vue')),
  },
  servicesByocLoginApi: {
    component: defineAsyncComponent(() => import('@/components/modals/services/byoc/ServicesByocLoginApiModal.vue')),
  },
  callbackUrl: {
    component: defineAsyncComponent(() => import('@/components/modals/services/byoc/CallbackUrlModal.vue')),
  },
  templatesTemplate: {
    component: defineAsyncComponent(() => import('@/components/modals/templates/TemplatesTemplateModal.vue')),
  },
  templatesShare: {
    component: defineAsyncComponent(() => import('@/components/modals/templates/TemplatesShareModal.vue')),
  },
  templatesDeleteCategory: {
    component: defineAsyncComponent(() => import('@/components/modals/templates/TemplatesDeleteCategoryModal.vue')),
  },
  addAccountingEmail: {
    component: defineAsyncComponent(() => import('@/components/modals/account/invoices/AddAccountingEmail.vue')),
  },
  newSmsSurvey: {
    component: defineAsyncComponent(() => import('@/components/modals/services/smsSurvey/NewSmsSurvey.vue')),
  },
  selectSearchCountry: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/SelectSearchCountryModal.vue')),
  },
  carrierLookupHistoryDetails: {
    component: defineAsyncComponent(() => import('@/components/modals/services/carrierLookup/CarrierLookupHistoryDetailsModal.vue')),
  },
  emailLookupHistoryDetails: {
    component: defineAsyncComponent(() => import('@/components/modals/services/emailLookup/EmailLookupHistoryDetailsModal.vue')),
  },
  recipientsSurvey: {
    component: defineAsyncComponent(() => import('@/components/modals/services/smsSurvey/RecipientsSurveyModal.vue')),
  },
  questionSurvey: {
    component: defineAsyncComponent(() => import('@/components/modals/services/smsSurvey/QuestionSurveyModal.vue')),
  },
  delaySurvey: {
    component: defineAsyncComponent(() => import('@/components/modals/services/smsSurvey/DelaySurveyModal.vue')),
  },
  scheduleSurvey: {
    component: defineAsyncComponent(() => import('@/components/modals/services/smsSurvey/ScheduleSurveyModal.vue')),
  },
  previewSurvey: {
    component: defineAsyncComponent(() => import('@/components/modals/services/smsSurvey/PreviewSurveyModal.vue')),
  },
  eventSummaryAuditLogs: {
    component: defineAsyncComponent(() => import('@/components/modals/reporting/EventSummaryAuditLogsModal.vue')),
  },
  downloadModal: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/DownloadModal.vue')),
  },
  editSenderModal: {
    component: defineAsyncComponent(() => import('@/components/modals/services/countries/EditSenderModal.vue')),
  },
  grayTileButtons: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/GrayTileButtonsModal.vue')),
  },
  servicesNumbersLabel: {
    component: defineAsyncComponent(() => import('@/components/modals/services/numbers/ServicesNumbersLabelModal.vue')),
  },
  singleCheckbox: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/SingleCheckboxModal.vue')),
  },
  workspaceDeactivateUser: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/users/WorkspaceDeactivateUserModal.vue')),
  },
  customField: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/customField/CustomFieldModal.vue')),
  },
  calendarModal: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/CalendarModal.vue')),
  },
  senderPreview: {
    component: defineAsyncComponent(() => import('@/components/modals/tickets/SenderPreviewModal.vue')),
  },
  chatsRejectLiveChat: {
    component: defineAsyncComponent(() => import('@/components/modals/chats/ChatsRejectLiveChat.vue')),
  },
  chatsFilters: {
    component: defineAsyncComponent(() => import('@/components/modals/chats/ChatsFiltersModal.vue')),
  },
  whatsAppQrCode: {
    component: defineAsyncComponent(() => import('@/components/modals/chats/settings/whatsApp/WhatsAppQrCode.vue')),
  },
  socialQrCode: {
    component: defineAsyncComponent(() => import('@/components/modals/chats/settings/SocialQrCode.vue')),
  },
  qrCodeModal: {
    component: defineAsyncComponent(() => import('@/components/modals/gettingStarted/QrCodeModal.vue')),
  },
  whatsAppLinkNumbers: {
    component: defineAsyncComponent(() => import('@/components/modals/chats/settings/whatsApp/WhatsAppLinkNumbersModal.vue')),
  },
  ticketSettingsAuthenticate: {
    component: defineAsyncComponent(() => import('@/components/modals/tickets/settings/TicketsSettingsAuthenticateModal.vue')),
  },
  ticketsSubdomain: {
    component: defineAsyncComponent(() => import('@/components/modals/tickets/settings/TicketsSettingsSubdomainModal.vue')),
  },
  ticketsFilteredViews: {
    component: defineAsyncComponent(() => import('@/components/modals/tickets/settings/TicketsSettingsFilteredViewsModal.vue')),
  },
  webWidgetEdit: {
    component: defineAsyncComponent(() => import('@/components/modals/chats/WebWidgetEditModal.vue')),
  },
  widgetSendInstructions: {
    component: defineAsyncComponent(() => import('@/components/modals/chats/WidgetSendInstructionsModal.vue')),
  },
  workspacePasswordPolicy: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/workspace/WorkspacePasswordPolicyModal.vue')),
  },
  plainText: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/PlainTextModal.vue')),
  },
  userLogins: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/workspace/UserLoginsModal.vue')),
  },
  ticketsCannedResponses: {
    component: defineAsyncComponent(() => import('@/components/modals/tickets/settings/TicketsCannedResponsesModal.vue')),
  },
  ticketsPublicReply: {
    component: defineAsyncComponent(() => import('@/components/modals/tickets/settings/TicketsSettingsReplyModal.vue')),
  },
  deals: {
    component: defineAsyncComponent(() => import('@/components/modals/deals/DealsModal.vue')),
  },
  connectDescriptionModal: {
    component: defineAsyncComponent(() => import('@/components/modals/admin/plans/ConnectDescriptionModal.vue')),
  },
  dealsDetails: {
    component: defineAsyncComponent(() => import('@/components/modals/deals/DealsDetailsModal.vue')),
  },
  dealsDetailsEdit: {
    component: defineAsyncComponent(() => import('@/components/modals/deals/DealsDetailsEditModal.vue')),
  },
  selectTemplate: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/SelectTemplateModal.vue')),
  },
  templatePreview: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/TemplatePreviewModal.vue')),
  },
  dealsForm: {
    component: defineAsyncComponent(() => import('@/components/modals/deals/DealsNewPipelineModal.vue')),
  },
  selectEntity: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/SelectEntityModal.vue')),
  },
  contactsOrganizationsTableModal: {
    component: defineAsyncComponent(() => import('@/components/modals/contacts/organizations/ContactsOrganizationsTableModal.vue')),
  },
  editSample: {
    component: defineAsyncComponent(() => import('@/components/modals/shared/EditSampleModal.vue')),
  },
  addCredit: {
    component: defineAsyncComponent(() => import('@/components/modals/header/AddCreditModal.vue')),
  },
  dealsDelete: {
    component: defineAsyncComponent(() => import('@/components/modals/deals/DealsDeleteModal.vue')),
  },
}
