
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { formatTime } from '@/services/utils'

export default defineComponent({
  components: {
    TmButton,
  },
  props: {
    duration: {
      type: Number,
      default: 1,
    },
    timeStep: {
      type: Number,
      default: 1000,
    },
    prefix: {
      type: String,
    },
    buttonText: {
      type: String,
    },
    disable: {
      type: Boolean,
    },
    inline: {
      type: Boolean,
    },
    startWithButton: {
      type: Boolean,
    },
  },
  setup(props) {
    const counting = ref(false)
    const timer = ref('')

    const timerStart = () => {
      counting.value = true
      let durationMinutes = props.duration * 60 * 1000
      timer.value = formatTime(durationMinutes)

      const timerId = setInterval(() => {
        durationMinutes -= props.timeStep
        timer.value = formatTime(durationMinutes)

        if (durationMinutes < 1000) {
          counting.value = false
          clearInterval(timerId)
        }
      }, props.timeStep)
    }

    if (!props.startWithButton) {
      timerStart()
    }

    return {
      timer,
      counting,
      timerStart,
    }
  },
})
