import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "lh-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.counting)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["lh-20 light--text font-weight-semi-bold", {
        'subhead-semi-bold-16 light--text': !_ctx.inline,
      }])
        }, [
          _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({ prefix: _ctx.prefix, timer: _ctx.timer })), () => [
            _createTextVNode(_toDisplayString(_ctx.prefix) + " " + _toDisplayString(_ctx.timer), 1)
          ])
        ], 2))
      : (_openBlock(), _createBlock(_component_tm_button, {
          key: 1,
          "text-link": "",
          disable: _ctx.disable,
          size: _ctx.inline ? '' : 'xLarge',
          class: "primary--text",
          onClick: _ctx.timerStart
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.buttonText), 1)
          ]),
          _: 1
        }, 8, ["disable", "size", "onClick"]))
  ]))
}