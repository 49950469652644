
import { defineComponent } from 'vue'
import { modalsConfig } from '@/components/modals/modalsConfig'
import { useModals } from '@/compositions/modals'
import type { ModalNames } from '@/components/modals/types'

export default defineComponent({
  components: {
    ...modalsConfig,
  },
  setup() {
    const { getModalsNames, getModalsProps } = useModals()
    const modalNames = getModalsNames()
    const modalsProps = getModalsProps()
    const getModalComponent = (name: ModalNames) => {
      return modalsConfig[name].component
    }
    const getProps = (name: ModalNames) => {
      return modalsProps.value[name]
    }

    return {
      modalNames,
      modalsProps,
      getModalComponent,
      getProps,
    }
  },
})
